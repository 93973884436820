.kwn-pacients-count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 94vw;

}


.kavan_admin_main_container {
  padding-bottom: 16vh;

}

.kwn-pacients-table-view table {
  width: 100%;
  margin-top: 3.3rem;
  border-collapse: collapse;
}

.kwn-pacients-table-view th {
  font-weight: 500;
  font-family: var(--font-family);
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 0.7rem;
  color: #000;
}

.kwn-pacients-table-view thead {
  border-bottom: 1px solid #474748;
}

.kwn-pacients-table-view tr {
  border-bottom: 1px solid #666;
}

.kwn-pacients-table-view td {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  font-weight: 400;
  font-family: var(--font-family);
  font-size: 1.7rem;
  text-align: left;
  padding-bottom: 0.7rem;
  color: #000;
}

.kawan-pacient-table-create-visit p {
  font-family: var(--font-family);
  font-size: 1.7rem;
  color: #3080ed;
  text-decoration: underline;
  cursor: pointer;
}

.kwn-heading-pacients-count-container {
  margin-left: 5rem;

}

.kwn-search {

  width: 56vw;
}


/* src/UserCard.css */

.user-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.user-card {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 16px;
  width: 210px;
  margin-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 8px;
}

.user-image {
  width: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 60px;
  border-radius: 50%;
  margin-right: 16px;
}

.short-name {
  font-size: 1.8rem;
  color: #fff;
  font-family: var(--font-family);
}

.user-image2 {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 3px !important;
  text-align: center;
}

.user-details {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.user-name-2 {
  margin: 0;
  font-size: 2rem;
  font-family: var(--font-family);
  white-space: nowrap;
  color: #006039;
}

.user-dob,
.user-gender {
  margin: 5px 0;
  font-size: 12px;
  color: #666;
}

.view-details-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #006039;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

.view-details-button:hover {
  background-color: #1aaf73;
}


/* src/UserModal.css */
.user-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-modal {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 1000px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  height: 60%;

}

.model-scroll {
  overflow-y: auto;
  max-height: 50%;
}

.user-modal-name {
  margin-bottom: 20px;
  font-family: var(--font-family);
  font-size: 2.5em;
  color: #333;
}

.table-label {
  color: #006039;
  font-family: var(--font-family);
  font-size: 1.3em;
}

.table-value {
  font-size: 1.2em;
  font-family: var(--font-family);
}

.user-modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.user-modal-table th,
.user-modal-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.user-modal-appointee-image {
  width: 60px;
  height: 60px;
  border-radius: 60%;
  margin-right: 10px;
}

.user-modal-close {
  padding: 10px 20px;
  background-color: #006039;
  font-family: var(--font-family);
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.user-modal-close:hover {
  background-color: #1aaf73;
}




/* -------------Media Query--------- */

@media screen and (max-width: 650px) {
  .kwn-pacients-count-container {
    flex-direction: column;
    align-items: center;
  }

  .kwn-pacients-table-view table thead {
    display: none;
  }

  .kwn-pacients-table-view table tr {
    display: block;
  }

  .kwn-pacients-table-view table td {
    display: block;
    text-align: right;
  }

  .kwn-pacients-table-view table td:last-child {
    border-bottom: 0;
  }

  .kwn-pacients-table-view table td::before {
    content: attr(data-label);
    float: left;
    font-weight: 600;
    font-size: 1.6rem;
  }

  .kwn-heading-pacients-count-container {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 420px) {
  .kwn-heading-pacients-count-container {
    margin-left: 0rem;
  }
}